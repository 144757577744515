<template>
    <div class="wrap">
        <header class="header">
            <el-image :src='png_bg' style="height:42px; width: 42px; margin: 12px 32px 0 32px;" />
            <!-- <h4 style="color:gray;letter-spacing: 1px;">四川经纬高技能人才网</h4>  -->
            <!-- <h3 style="margin-right: 12px; color: #b8c0cb;"> | </h3> -->
            <h3>{{title}}</h3>
            <el-button @click="handleCollapse" style="border:0px;height: 32px;margin: 14px;">{{isCollapse ? '展开' :
            '收起'}}</el-button>
        </header>
        <content class="abouts" v-loading="loading">
            <el-menu :default-active="curID" class="el-menu-vertical-demo menu" @select="selectIndex"
                :collapse="isCollapse">
                <el-menu-item v-for="item in menuConfig" :key="item.id" :index="item.id">
                    <i class="el-icon-menu"></i>
                    <span slot="title">{{item.title}}</span>
                </el-menu-item>
            </el-menu>
            <div v-html="curHTML" class="content"></div>
        </content>
    </div>
</template>


<script>
import { baseURL } from '@/utils/request/apis'
import png_bg from '@/assets/images/logo-icon.png'
import introduction from './introduction'

export default {
    data() {
        return {
            title: "",
            menuConfig: [],
            curID: '',
            curHTML: '',
            png_bg,
            loading: false,
            isCollapse: false
        }
    },
    methods: {
        getInfo(usehelpType) {
            let url = baseURL + `/lz_news_information/lzNewsInformation/listByCondition?typeName=9&usehelpType=${usehelpType}`
            this.loading = true
            this.$http.get(url).then(res => {
                // console.log(res)
                if (res.result && res.result.records.length > 0) {
                    const { records } = res.result
                    this.curID = records[0].id
                    this.curHTML = records[0].publishContent
                    for (const item of records) {
                        this.menuConfig.push({
                            id: item.id,
                            publishContent: item.publishContent,
                            title: item.title
                        })
                    }
                }
            }).finally(() => {
                this.loading = false
            })
        },
        selectIndex(e) {
            // console.log(e)
            // this.curIndex = e
            for (const item of this.menuConfig) {
                if (item.id === e) {
                    this.curHTML = item.publishContent
                    // console.log(this.curHTML)
                }
            }
        },
        handleCollapse() {
            this.isCollapse = !this.isCollapse
        }
    },
    created() {
        let type = this.$route.path.split('/').slice(-1)[0]
        switch (type) {
            case 'agreement':
                this.title = '法律协议'
                this.getInfo(0)
                break
            case 'license':
                this.title = '资质公示'
                this.getInfo(1)
                break
            case 'introduction':
                this.title = '公司简介'
                this.menuConfig.push({
                    id: 'introduction',
                    publishContent: introduction,
                    title: '公司简介'
                })
                this.curID = this.menuConfig[0].id
                this.curHTML = this.menuConfig[0].publishContent
                break
        }
    }
}


</script>


<style scoped>
.wrap {
    background: #f0f0f0;
}

.header {
    height: 64px;
    width: 100%;
    padding: 6px 0;
    background: white;
    display: flex;
}

.abouts {
    margin-top: 12px;
    display: flex;
    min-height: 100vh;
    padding: 0 24px;
}

.menu {}

.content {
    flex: 1;
    padding: 12px;
    box-sizing: border-box;
    background: white;
    margin: 0 0 0 12px;
}
</style>