const introductionHTML = `
    <div style='width:100%;height:100%;text-align:center;'>
        <h3 style='font-size:22pt'>四川经纬高技能人才发展有限公司简介</h3>
        <p style='font-size:14pt;text-align:left;font-family: 仿宋; line-height:24pt;'>
        &nbsp;&nbsp;&nbsp;&nbsp;四川经纬高技能人才发展有限公司于2022年1月注册成立，是泸州职业技术学院全资国有企业四川经纬教育集团有限公司旗下一家控股子公司。公司定位为聚集高技能人才的功能性平台，辐射成渝地区双城经济社会发展的人才需求，打造以高技能人才全链条的一站式服务平台，以“科研中心、信息平台、标准开发、就业推荐、培训服务、人才猎头、服务外包和校企合作”为主要职能，把“搭台聚能、招才引智”作为重大历史使命，为各类高技能人才和用人主体提供专业服务。
        </p>
    </div>
`;

export default introductionHTML;
